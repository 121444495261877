import "./instrument";
import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { lightTheme, darkTheme } from "./theme";
import StoreProvider from "./redux/slices/StoreProvider";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/react";

const Root = () => {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <StoreProvider>
        <App darkMode={darkMode} setDarkMode={setDarkMode} />
      </StoreProvider>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"), {
  onUncaughtError: Sentry.reactErrorHandler((error, errorInfo) => {
    console.warn("Uncaught error", error, errorInfo.componentStack);
  }),
  onCaughtError: Sentry.reactErrorHandler(),
  onRecoverableError: Sentry.reactErrorHandler(),
});

// Conditionally initialize GA4 only if the environment is production
if (process.env.REACT_APP_ENV === "production" && process.env.GA4_KEY) {
  ReactGA.initialize(process.env.GA4_KEY);
}

root.render(
  // <React.StrictMode>
  <Root />
  // </React.StrictMode>
);

reportWebVitals();
