import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useState, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";

const BetaRoute = ({ children }) => {
    const { isBeta } = useAuth();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        if (!isBeta) {
            setOpenSnackbar(true);
            // Delay the redirection to allow Snackbar to show
            setTimeout(() => setRedirect(true), 2000); // 2 seconds delay
        }
    }, [isBeta]);

    if (redirect) return <Navigate to="/" />;

    return (
        <>
            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={2000} 
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert severity="warning" onClose={() => setOpenSnackbar(false)}>
                    Only beta users are allowed on this route. ❌
                </Alert>
            </Snackbar>

            {isBeta && children}
        </>
    );
};

export default BetaRoute;
