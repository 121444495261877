import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";

import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  useTheme,
  Box,
  Divider,
} from "@mui/material";
import {
  Home as HomeIcon,
  BarChart as BarChartIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
  Lightbulb as LightbulbIcon,
  Brightness4 as Brightness4Icon,
  Brightness7 as Brightness7Icon,
  Settings as SettingsIcon,
  ExitToApp as LogoutIcon,
  AssignmentInd as AssignmentIndIcon,
  Grading as GradingIcon,
} from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";

const SidebarNavigation = ({ darkMode, setDarkMode }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isMatched, setIsMatched] = useState(false);
  const userRole = localStorage.getItem("role");
  const location = useLocation();
  const theme = useTheme();
  const { logout, handleRevert } = useAuth();
  const navigate = useNavigate();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const LEE_USER_ID = process.env.LEE_USER_ID;

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleLogout = () => {
    logout();
    Cookies.remove("token");

    navigate("/login");
  };
  const switchToAdmin = async () => {
    try {
      const token = localStorage.getItem("token");
      const resp = await axios.post(
        `${API_BASE_URL}/api/auth/admin/revert-role`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (resp.status === 200) {
        handleRevert(resp);
        navigate("/customers-list");
      }
    } catch (error) {
      console.error("Logout Error:", error.response?.data || error.message);
      throw error.response?.data || error;
    }
  };
  // const navItems = [
  //   { name: " Select Metrics", path: "/", icon: <HomeIcon /> },
  //   {
  //     name: "Smart List Reports",
  //     path: "/smart-lists",
  //     icon: <GradingIcon />,
  //   },
  //   { name: "Stage Reports", path: "/stage-reports", icon: <BarChartIcon /> },
  //   { name: "Leaderboard", path: "/leaderboard", icon: <PersonIcon /> },
  //   { name: "Agent Insights", path: "/lead-insights", icon: <LightbulbIcon /> },
  //   { name: "Lead Claims", path: "/lead-claims", icon: <AssignmentIndIcon /> },
  //   { name: "Settings", path: "/settings", icon: <SettingsIcon /> },
  // ];
  console.log("lee user id =>", { LEE_USER_ID });
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user._id == "67a374f6e47daa5dc17e08cf") {
        setIsMatched(true);
      } else {
        setIsMatched(false);
      }
    } else {
      setIsMatched(false);
    }
  }, []);
  const TopNavItems = [
    isMatched && {
      name: "Leaderboard",
      path: "/leaderboard",
      icon: <PersonIcon />,
    },
    { name: "Lead Claims", path: "/lead-claims", icon: <AssignmentIndIcon /> },
    { name: "Smart List Reports", path: "/smart-lists", icon: <GradingIcon /> },
    { name: "Stage Reports", path: "/stage-reports", icon: <BarChartIcon /> },
    { name: "Agent Insights", path: "/agent-insights", icon: <LightbulbIcon /> },
  ].filter(Boolean); // Remove falsy values (e.g., `false` when `isMatched` is `false`)

  const BottomNavItems = [
    { name: " Select Metrics", path: "/", icon: <HomeIcon /> },
    { name: "Settings", path: "/settings", icon: <SettingsIcon /> },
  ];
  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        width: isOpen ? 270 : 56,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isOpen ? 270 : 56,
          boxSizing: "border-box",
          backgroundColor: theme.palette.background.default,
          transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        },
      }}
    >
      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <ListItem
            sx={{
              display: "flex",
              justifyContent: isOpen ? "space-between" : "center",
              padding: 2,
            }}
          >
            {isOpen && (
              <Typography variant="h6" noWrap component="div">
                <img
                  src="/icons/favicon/slz-logo-v-1.png"
                  alt=""
                  class=""
                ></img>
              </Typography>
            )}
            <IconButton onClick={toggleSidebar}>
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </ListItem>
          {TopNavItems.map((item) => (
            <ListItem
              button
              key={item.name}
              component={Link}
              to={item.path}
              selected={location.pathname === item.path}
              sx={{
                justifyContent: isOpen ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              {isOpen && <ListItemText primary={item.name} />}
            </ListItem>
          ))}
        </Box>
        <Box sx={{ pb: 2 }}>
          <Divider />
          <ListItem
            button
            onClick={toggleDarkMode}
            sx={{
              justifyContent: isOpen ? "flex-start" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </ListItemIcon>
            {isOpen && <ListItemText primary="Toggle Dark Mode" />}
          </ListItem>
          <Box>
            {BottomNavItems.map((item) => (
              <ListItem
                button
                key={item.name}
                component={Link}
                to={item.path}
                selected={location.pathname === item.path}
                sx={{
                  justifyContent: isOpen ? "flex-start" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {isOpen && <ListItemText primary={item.name} />}
              </ListItem>
            ))}
          </Box>
          {userRole === "admin" ? (
            <ListItem
              button
              onClick={switchToAdmin}
              sx={{
                justifyContent: isOpen ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              {isOpen && <ListItemText primary="Switch to Admin" />}
            </ListItem>
          ) : (
            <ListItem
              button
              onClick={handleLogout}
              sx={{
                justifyContent: isOpen ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              {isOpen && <ListItemText primary="Logout" />}
            </ListItem>
          )}
        </Box>
      </List>
    </Drawer>
  );
};

export default SidebarNavigation;
