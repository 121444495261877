import React, { useState, useEffect, Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ThemeProvider, CssBaseline, Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
//import SubscriptionManagement from "./components/SubscriptionManagement";
import { lightTheme, darkTheme } from "./theme";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { AgentColorProvider } from "./contexts/AgentColorContext";
import ErrorBoundary from "./components/ErrorBoundary";
import axios from "axios";
import { io } from "socket.io-client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
//import LeadClaimDashboard from "./components/LeadClaimDashboard";
import { sendPageView } from "./gtag";
//import TypeformEmbed from "./components/common/type-form-embed";
import Loader from "./app-component/Loader";
import SidebarNavigation from "./components/SidebarNavigation";
import BetaRoute from "./components/BetaRoute";

const DailyLogTable = lazy(() => import("./components/DailyLogTable"));
const StageCounts = lazy(() =>
  import("./components/stage-reports/StageCounts")
);
const SelectionComponent = lazy(() =>
  import("./components/SelectionComponent")
);
const Scoreboard = lazy(() => import("./components/Scoreboard"));
const ScoreboardSoon = lazy(() => import("./components/ScoreboardSoon"));
const LeadInsights = lazy(() =>
  import("./components/leadInsights/LeadInsights")
);
const Login = lazy(() => import("./components/Login"));
const AdminLogin = lazy(() => import("./components/AdminLogin"));
const CustomerList = lazy(() => import("./components/CustomerLists"));
const Register = lazy(() => import("./components/Register"));
const ForgotPassword = lazy(() => import("./components/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const Settings = lazy(() => import("./components/Settings"));
const APIKeyForm = lazy(() => import("./components/APIKeyForm"));
const SubscriptionManagement = lazy(() =>
  import("./components/SubscriptionManagement")
);
const LeadClaimDashboard = lazy(() =>
  import("./components/LeadClaimDashboard")
);
const TypeformEmbed = lazy(() => import("./components/common/type-form-embed"));

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    sendPageView(location.pathname);
  }, [location]);

  return null;
};
const API_BASE_URL =
  // process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";
  process.env.REACT_APP_API_BASE_URL;

const SubscriptionSuccess = () => {
  console.log("SubscriptionSuccess component rendered");
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");

    const verifySubscription = async () => {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      if (sessionId) {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/auth/register/success?session_id=${sessionId}`
          );
          // const data = await response.json();
          const data = await response.data;
          console.log("Hello from successfully registered page");
          console.log(data);
          const token = data.user.token;
          console.log(token);
          if (token) {
            await login(token);
            navigate("/settings");
          } else {
            navigate("/login");
          }
        } catch (error) {
          console.error("Error verifying subscription:", error);
          navigate("/login");
        }
      }
    };

    verifySubscription();
  }, [location, navigate, login]);

  return (
    <Box sx={{ mt: 4, textAlign: "center" }}>
      <h1>Verifying your subscription...</h1>
    </Box>
  );
};

const PrivateRoute = ({ children }) => {
  console.log("PrivateRoute rendered");
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log("user from app route");
  console.log(user);
  console.log(user?.fubApiKey);
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    user.subscriptionStatus !== "active" &&
    location.pathname !== "/subscription" &&
    location.pathname !== "/register-payment" &&
    location.pathname !== "/subscription-success" &&
    location.pathname !== "/settings"
  ) {
    console.log("User not subscribed, redirecting to register-payment");
    return (
      <Navigate to="/register-payment" state={{ from: location }} replace />
    );
  }

  console.log("User authenticated and subscribed, rendering children");
  return children;
};

const AppContent = () => {
  const [darkMode, setDarkMode] = useState(false);
  const theme = darkMode ? darkTheme : lightTheme;
  const { user } = useAuth();
  const location = useLocation();

  const shouldHideSidebar = () => {
    return location.pathname === "/customers-list";
  };
  const hideSidebar = () => {
    return location.pathname === "/admin-login";
  };
  const hidebar = () => {
    return location.pathname === "/login";
  };
  const hideOnRegister = () => {
    return location.pathname === "/register";
  };
  const navigate = useNavigate();
  useEffect(() => {
    shouldHideSidebar();
    hideSidebar();
    hidebar();
    hideOnRegister();
  }, [location]);
  if (user && "role" in user && user.role !== "customer") {
    navigate("/customers-list");
  }
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={null}>
        <TypeformEmbed />
        <CssBaseline />
        <Box
          // component="main"
          sx={{ display: "flex" }}
          width="100%"
        >
          {user &&
            !shouldHideSidebar() &&
            !hideSidebar() &&
            !hidebar() &&
            !hideOnRegister() && (
              <SidebarNavigation
                darkMode={darkMode}
                setDarkMode={setDarkMode}
              />
            )}
          <Box
            p={4}
            sx={{ display: "flex", flex: 1, width: "calc(100% - 270px)" }}
          >
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/admin-login" element={<AdminLogin />} />
              <Route path="/customers-list" element={<CustomerList />} />

              {/* ----Conflicts are below-- */}
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <SelectionComponent />
                  </PrivateRoute>
                }
              />

              <Route
                path="/smart-lists"
                element={
                  <PrivateRoute>
                    <DailyLogTable />
                  </PrivateRoute>
                }
              />

              <Route
                path="/stage-reports"
                element={
                  <PrivateRoute>
                    <StageCounts />
                  </PrivateRoute>
                }
              />
              <Route
                path="/leaderboard"
                element={
                  <BetaRoute>
                    <PrivateRoute>
                      <Scoreboard />
                    </PrivateRoute>
                  </BetaRoute>
                }
              />
              <Route
                path="/leaderboard-soon"
                element={
                  <PrivateRoute>
                    <ScoreboardSoon />
                  </PrivateRoute>
                }
              />
              <Route
                path="/agent-insights"
                element={
                  <PrivateRoute>
                    <LeadInsights />
                  </PrivateRoute>
                }
              />
              <Route
                path="/api-key"
                element={
                  <PrivateRoute>
                    <APIKeyForm />
                  </PrivateRoute>
                }
              />
              <Route
                path="/lead-claims"
                element={
                  <PrivateRoute>
                    <LeadClaimDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                }
              />
              {/* <Route path="/" element={<PrivateRoute><SelectionComponent /></PrivateRoute>} />
            <Route path="/daily-log" element={<PrivateRoute><DailyLogTable socket={socket} /></PrivateRoute>} />
            <Route path="/stage-reports" element={<PrivateRoute><StageCounts /></PrivateRoute>} />
            <Route path="/leaderboard" element={<PrivateRoute><Scoreboard /></PrivateRoute>} />
            <Route path="/lead-insights" element={<PrivateRoute><LeadInsights /></PrivateRoute>} />
            <Route path="/api-key" element={<PrivateRoute><APIKeyForm /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} /> */}
              {/* ----Conflicts are below-- */}
              <Route
                path="/subscription"
                element={<SubscriptionManagement />}
              />

              <Route
                path="/register-payment"
                element={<SubscriptionManagement />}
              />
              <Route
                path="/subscription-success"
                element={<SubscriptionSuccess />}
              />
              <Route
                path="/dashboard"
                element={
                    <PrivateRoute>
                      <SelectionComponent />
                    </PrivateRoute>
                }
              />
              <Route path="*" element={<div>404 - Not Found</div>} />
            </Routes>
          </Box>
        </Box>
      </Suspense>
    </ThemeProvider>
  );
};

const App = () => {
  const [stripePromise, setStripePromise] = useState(null);
  const queryClient = new QueryClient(); // Add this line

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const stripe = await loadStripe(
          String(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );

        setStripePromise(stripe);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      }
    };

    initializeStripe();
  }, []);

  if (!stripePromise) {
    return <div>Loading Stripe...</div>;
  }

  return (
    <Router>
      <TrackPageView />
      <Suspense fallback={null}>
        <AuthProvider>
          <ErrorBoundary>
            <AgentColorProvider>
              <QueryClientProvider client={queryClient}>
                <Elements stripe={stripePromise}>
                  <AppContent />
                </Elements>
              </QueryClientProvider>
            </AgentColorProvider>
          </ErrorBoundary>
        </AuthProvider>
      </Suspense>
    </Router>
  );
};

export default App;
