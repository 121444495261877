export const sendPageView = (url) => {
  if (typeof window.gtag !== "function") return;
  window.gtag("config", "G-LQR4BHMMMC", { page_path: url });
};

export const sendEvent = ({ category, action, label, value }) => {
  if (typeof window.gtag !== "function") return;
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
