"use client";
import { Provider } from "react-redux";
//import { PersistGate } from "redux-persist/integration/react";
// import persistStore from "redux-persist/es/persistStore";
//import { persistStore } from "redux-persist";
import { store } from "../store";
//import storage from 'redux-persist/lib/storage'

// import { useRef } from "react";

const StoreProvider = ({ children }) => {
  // const storeRef = useRef();
  // if (!storeRef.current) {
  //   storeRef.current = store;
  // }
/*   const persistor = persistStore(store, {
    key: 'root',
    storage: storage
  }); */
  return (
    // <Provider store={storeRef.current}>
    <Provider store={store}>
     {/*  <PersistGate loading={<p> loading.. (Persist =={">"} Store) </p>} persistor={persistor}> */}
        {children}
 {/*      </PersistGate> */}
    </Provider>
  );
};

export default StoreProvider;


